<template>
<div class="c-app flex-row align-items-center bg-login">
    <CContainer>
        <b-row class="justify-content-center">
            <transition appear name="bounce">
                <b-col xl="4" lg="4" md="6" sm="8">
                    <CCardGroup>
                        <CCard class="p-4">
                            <CCardBody>
                                <validation-observer ref="observer" v-slot="{ handleSubmit }">
                                    <b-form @submit.stop.prevent="handleSubmit(login)">
                                        <div class="text-center mt-2 mb-4">
                                            <!-- <img src="img/logo_sig_black.png"
                                        alt="logo sig" width="200" > -->
                                            <img v-if="render" :src="datosCliente.logo" alt="logo sig" width="300px" class="img-fluid">
                                        </div>
                                        <!-- <b-alert :show="dismissCountDown" dismissible :variant="variant" @dismissed="dismissCountDown = 0" @dismiss-count-down="countDownChanged">{{ message }}</b-alert> -->

                                        <!--<p class="text-muted">Sign In to your account</p>-->
                                        <b-row>
                                            <b-col lg="12">
                                                <validation-provider name="usuario" :rules="{required: true}" v-slot="validationContext">
                                                    <b-input-group>
                                                        <b-input-group-prepend>
                                                            <b-button disabled variant="light">
                                                                <b-icon icon="person-fill"></b-icon>
                                                            </b-button>
                                                        </b-input-group-prepend>
                                                        <b-form-input type="text" :state="getValidationState(validationContext)" placeholder="Usuario" v-model.trim="usuario"></b-form-input>
                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                    </b-input-group>
                                                </validation-provider>
                                            </b-col>

                                            <b-col class="mt-3" lg="12">
                                                <validation-provider name="contraseña" :rules="{required: true}" v-slot="validationContext">
                                                    <b-input-group>
                                                        <b-input-group-prepend>
                                                            <b-button disabled variant="light">
                                                                <b-icon icon="lock-fill"></b-icon>
                                                            </b-button>
                                                        </b-input-group-prepend>
                                                        <b-form-input type="password" :state="getValidationState(validationContext)" placeholder="Contraseña" v-model.trim="contrasena"></b-form-input>
                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                    </b-input-group>
                                                </validation-provider>
                                            </b-col>

                                        </b-row>
                                        <template v-if="!overlayLogin">
                                            <b-row>
                                                <CCol col="12" class="text-center mt-3 mb-2">
                                                    <CButton color="dark" type="submit" class="px-4">Iniciar Sesión</CButton>
                                                </CCol>
                                            </b-row>
                                            <!-- <b-row>
                                            <CCol col="12" class="text-center">
                                                <CButton color="link" :to="{name:'Recuperar'}" class="px-0">Olvidaste tu Contraseña?</CButton>
                                            </CCol>
                                        </b-row> -->
                                        </template>
                                        <template v-if="overlayLogin">
                                            <b-overlay show rounded="lg" class="mt-5 mb-5" opacity="0.0">
                                                <template v-slot:overlay>
                                                    <div class="d-flex align-items-center ">
                                                        <b-spinner small type="grow" variant="secondary"></b-spinner>
                                                        <b-spinner type="grow" variant="dark"></b-spinner>
                                                        <b-spinner small type="grow" variant="secondary"></b-spinner>
                                                    </div>
                                                </template>
                                            </b-overlay>
                                        </template>
                                    </b-form>
                                </validation-observer>

                            </CCardBody>
                        </CCard>

                    </CCardGroup>
                </b-col>
            </transition>
        </b-row>
    </CContainer>
</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";
import firebase from 'firebase';
const dbClientes = firebase.firestore().collection('clientes');

export default {
    name: "login",
    data() {
        return {
            usuario: '',
            contrasena: '',
            overlayLogin: false,

            listLogin: [],

            render: false,

            datosCliente: {
                idCliente: "",
                razonSocial: "",
                path: "",
                logo: ""
            },

            direccionIp: '',
            navegador: '',
            sistemaOperativo: 'SO desconocido',
            disabled: true,
        };
    },
    methods: {
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        // countDownChanged(dismissCountDown) {
        //     this.dismissCountDown = dismissCountDown;
        // },
        // login() {
        //     let me = this;
        //     me.overlayLogin = true;
        //     axios
        //         .post(CONSTANTES.URL_RUTA_SERVICIOS + "auth/login-cliente", {
        //             usuario: me.usuario.replace(/ /g, ""),
        //             password: me.contrasena.replace(/ /g, ""),
        //             idCliente: me.datosCliente.idCliente,
        //             direccionIp: me.direccionIp,
        //             navegador: me.navegador,
        //             sistemaOperativo: me.sistemaOperativo
        //         })
        //         .then(function (response) {
        //             // if (response.data.token) {
        //             //     localStorage.token = response.data.token;
        //             //     // localStorage.settings = JSON.stringify(response.data.settings);
        //             //     if (response.data.user.idTipoUsuario == 2) {
        //             //         localStorage.uud_session = me.getCharacters(50) + response.data.user.idTipoPerfil + me.getCharacters(48) + response.data.user.idTipoUsuario + me.getCharacters(45) + response.data.user.idUsuario + me.getCharacters(42) + response.data.user.idCliente + me.getCharacters(32);
        //             //         //mi idCliente: ",localStorage.uud_session.split("").splice(-33)[0],
        //             //         // "idUsuario: " ,localStorage.uud_session.split("").splice(-76)[0],
        //             //         // "idTipoUsuario:", localStorage.uud_session.split("").splice(-122)[0])

        //             //         me.$router.push({
        //             //             name: "Panel de Control",
        //             //         });
        //             //     }
        //             // } else {

        //             //     me.message = response.data.message;
        //             //     me.dismissCountDown = me.dismissSecs;
        //             // }
        //             if (response.data.token) {
        //                 if (response.data.uidType == 'c2c4ef67-2198-40a3-9c31-adf97d4b88ef') {
        //                     localStorage.token = response.data.token;
        //                     localStorage.settings = JSON.stringify(response.data.settings);
        //                     me.$router.push({
        //                         name: "Panel de Control",
        //                     });
        //                 } else {
        //                     me.swat('error', 'Acceso denegado');
        //                     me.overlayLogin = false;
        //                 }
        //             } else {
        //                 me.swat('error', response.data.message);
        //                 me.overlayLogin = false;
        //             }
        //             me.overlayLogin = false;
        //         })
        //         .catch(function (error) {
        //             me.swat('error', 'Algo salió mal');
        //             me.overlayLogin = false;
        //         });
        // },
        login() {
            let me = this;
            me.overlayLogin = true;
            // firebase.auth()
            //     .signInWithEmailAndPassword(me.usuario, me.contrasena)
            //     .then((res) => {
            //         if (res.user) {
            //             const userCollection = firebase.firestore().collection('users');
            //             userCollection
            //                 .where("uidAuth", "==", res.user.uid)
            //                 .where("estado", "==", 2)
            //                 .get()
            //                 .then((querySnapshot) => {
            //                     if (querySnapshot.docs.length > 0) {
            //                         querySnapshot.forEach((doc) => {
            //                             if (me.datosCliente.idCliente == doc.data().idCliente) {
            //                                 me.$store.commit('SET_USER_DATA', doc.data());
            //                                 // if(me.$store.state.user.rol == "administrador"){
            //                                 if(me.$store.state.user.rol == "guia"){
            //                                     me.$router.push({
            //                                         name: "Panel de Control",
            //                                     });
            //                                 }
                                            
            //                                 // else if (me.$store.state.user.rol == "master") {
            //                                 //     me.swat("error", "El usuario master no puede acceder desde aqui")
            //                                 //     me.overlayLogin = false;
            //                                 // }
            //                                 else {
            //                                     me.swat("error", "Este usuario no tiene rol!")
            //                                     me.overlayLogin = false;
            //                                 }
            //                             } else {
            //                                 firebase.auth().signOut().then(() => {
            //                                     me.overlayLogin = false;
            //                                     me.swat("error", "Usuario no encontrado");
            //                                 })
            //                             }
            //                         });
            //                     } else {
            //                         firebase.auth().signOut().then(() => {
            //                             me.overlayLogin = false;
            //                             me.swat("error", "Este usuario no esta activo");
            //                         })
            //                     }
            //                 })
            //                 .catch((err) => {
            //                     firebase.auth().signOut().then(() => {
            //                         me.overlayLogin = false;
            //                         me.swat("error", err);
            //                     })
            //                 });
            //         } else {
            //             firebase.auth().signOut().then(() => {
            //                 me.overlayLogin = false;
            //                 me.swat('error', "Algo salió mal");
            //             })
            //         }
            //     })
            //     .catch((err) => {
            //         //console.log(err)
            //         if(err.code=='auth/wrong-password'){
            //             me.swat('error', 'Contraseña incorrecta');
            //         }
            //         if(err.code=='auth/user-not-found'){
            //             me.swat('error', 'Usuario no encontrado');
            //         }
            //         if(err.code=='auth/too-many-requests'){
            //             me.swat('error', 'Acceso deshabilitado temporalmente, intente de nuevo más tarde');
            //         }
            //         me.overlayLogin = false;
            //     });
            const userCollection = firebase.firestore().collection('users');
            userCollection
            .where("usernrodoc", "==", me.usuario)
            .where("passnrodoc", "==", me.contrasena)
            .get()
            .then((querySnapshot) => {
                if(querySnapshot.docs.length > 0) {
                    me.listLogin = [];
                    querySnapshot.forEach((doc) => {
                        me.listLogin.push({
                            id: doc.id,
                            apellidoMaterno: doc.data().apellidoMaterno,
                            apellidoPaterno: doc.data().apellidoPaterno,
                            email: doc.data().email,
                            estado: doc.data().estado,
                            fechaCreacion: doc.data().fechaCreacion,
                            fechaModificacion: doc.data().fechaModificacion,
                            fechaNacimiento: doc.data().fechaNacimiento,
                            idCliente: doc.data().idCliente,
                            idTipoDocumento: doc.data().idTipoDocumento,
                            nombre: doc.data().nombre,
                            passnrodoc: doc.data().passnrodoc,
                            rol: doc.data().rol,
                            telefono: doc.data().telefono,
                            usernrodoc: doc.data().usernrodoc,
                        });
                        if(doc.data().estado == 2){
                            if(me.datosCliente.idCliente == doc.data().idCliente){
                                // me.$store.commit('SET_USER_DATA', doc.data());
                                me.$store.commit('SET_USER_DATA', me.listLogin[0]);
                                // me.$store.commit('SET_MODULES_DATA', me.listLogin[0]);
                                if(me.$store.state.user.rol == "administrador"){
                                    me.$router.push({
                                        name: "Panel de Control",
                                    });
                                }else if(me.$store.state.user.rol == "guia"){
                                    me.swat("error", "El usuario guia no puede acceder desde aqui");
                                    me.overlayLogin = false;
                                }else if (me.$store.state.user.rol == "master"){
                                    me.swat("error", "El usuario master no puede acceder desde aqui");
                                    me.overlayLogin = false;
                                }else{
                                    me.swat("error", "Este usuario no tiene rol!!!")
                                    me.overlayLogin = false;
                                }
                            }else{
                                me.overlayLogin = false;
                                me.swat("error", "Este usuario no pertenece a esta empresa");
                            }
                        }else{
                            me.overlayLogin = false;
                            me.swat("error", "Este usuario no esta activo");
                        }
                    });

                }else{
                    me.overlayLogin = false;
                    me.swat("error", "Usuario no encontrado");
                }
            })
            .catch((err) => {
                me.overlayLogin = false;
                me.swat("error", err);
            });
        },
        obtenerDatosAccesosSistema() {
            let me = this;
            axios
                .get("https://ipgeolocation.abstractapi.com/v1/?api_key=cf8e35dea0004941a8b17ea2978b8d3a")
                .then(function (response) {
                    //console.log(response.data.ip_address)
                    me.direccionIp = response.data.ip_address;
                    let {
                        userAgent
                    } = navigator
                    if (userAgent.indexOf("Win") != -1) me.sistemaOperativo = "Windows";
                    if (userAgent.indexOf("Mac") != -1) me.sistemaOperativo = "Macintosh";
                    if (userAgent.indexOf("Linux") != -1) me.sistemaOperativo = "Linux";
                    if (userAgent.indexOf("Android") != -1) me.sistemaOperativo = "Android";
                    if (userAgent.indexOf("like Mac") != -1) me.sistemaOperativo = "iOS";

                    let browser = (() => {
                        let test = regexp => regexp.test(userAgent);

                        switch (true) {
                            case test(/edg/i):
                                return "Microsoft Edge";
                            case test(/trident/i):
                                return "Microsoft Internet Explorer";
                            case test(/firefox|fxios/i):
                                return "Mozilla Firefox";
                            case test(/opr\//i):
                                return "Opera";
                            case test(/ucbrowser/i):
                                return "UC Browser";
                            case test(/samsungbrowser/i):
                                return "Samsung Browser";
                            case test(/chrome|chromium|crios/i):
                                return "Google Chrome";
                            case test(/safari/i):
                                return "Apple Safari";
                            default:
                                return "Otro navegador";
                        }
                    })();
                    me.navegador = browser;
                })
                .catch(function (error) {

                });

            // fetch('https://api.ipify.org?format=json')
            //     .then(x => x.json())
            //     .then(({
            //         ip
            //     }) => {
            //         this.direccionIp = ip;
            //         let sistema = "SO desconocido";
            //         if (navigator.userAgent.indexOf("Win") != -1) sistema = "Windows";
            //         if (navigator.userAgent.indexOf("Mac") != -1) sistema = "Macintosh";
            //         if (navigator.userAgent.indexOf("Linux") != -1) sistema = "Linux";
            //         if (navigator.userAgent.indexOf("Android") != -1) sistema = "Android";
            //         if (navigator.userAgent.indexOf("like Mac") != -1) sistema = "iOS";

            //         this.sistemaOperativo = sistema;

            //         let browser = (function () {
            //             let test = function (regexp) {
            //                 return regexp.test(window.navigator.userAgent)
            //             }
            //             switch (true) {
            //                 case test(/edg/i):
            //                     return "Microsoft Edge";
            //                 case test(/trident/i):
            //                     return "Microsoft Internet Explorer";
            //                 case test(/firefox|fxios/i):
            //                     return "Mozilla Firefox";
            //                 case test(/opr\//i):
            //                     return "Opera";
            //                 case test(/ucbrowser/i):
            //                     return "UC Browser";
            //                 case test(/samsungbrowser/i):
            //                     return "Samsung Browser";
            //                 case test(/chrome|chromium|crios/i):
            //                     return "Google Chrome";
            //                 case test(/safari/i):
            //                     return "Apple Safari";
            //                 default:
            //                     return "Otro navegador";
            //             }
            //         })();
            //         this.navegador = browser;
            //     });
        },
        obtenerDatosCliente() {
            let me = this;

            // axios.defaults.headers.common[
            //     "Authorization"
            // ] = `Bearer ${localStorage.token}`;
            // axios
            //     .get(CONSTANTES.URL_RUTA_SERVICIOS + "auth/obtener-login-cliente", {
            //         params: {
            //             path: me.$route.params.rsCliente
            //         },
            //     })
            //     .then(function (response) {

            //         if (response.data.length == 0) {
            //             me.$router.push({
            //                 name: "Login",
            //             });
            //         }
            //         me.disabled = false;
            //         me.datosCliente.logo = response.data[0].urlLogo
            //         me.datosCliente.idCliente = response.data[0].idCliente
            //         me.render = true
            //     })
            //     .catch(function (error) {
            //         // me.swat('error','Algo salió mal')
            //     });
            let path = me.$route.params.rsCliente;

            dbClientes.where("path", "==", path)
                .onSnapshot((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        //console.log(doc.data());
                        let settings = {
                            path: me.$route.params.rsCliente,
                            primaryColor: doc.data().primaryColor,
                            secondaryColor: doc.data().secondaryColor,
                            logo: doc.data().urlLogo
                        };
                        localStorage.settings = JSON.stringify(settings);
                        me.disabled = false;
                        me.datosCliente.logo = doc.data().urlLogo
                        me.datosCliente.idCliente = doc.id
                        me.render = true
                    });
                });
        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        }

        // getCharacters(length) {
        //     var result = '';
        //     var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        //     var charactersLength = characters.length;
        //     for (var i = 0; i < length; i++) {
        //         result += characters.charAt(Math.floor(Math.random() * charactersLength));
        //     }
        //     return result;
        // }
    },
    watch: {
        '$route.params.rsCliente': function (rsCliente) {
            this.obtenerDatosCliente();
        }
    },
    mounted() {

        this.obtenerDatosCliente();
        //this.obtenerDatosAccesosSistema();
        //this.obtenerDatosCliente();
    }
};
</script>

<style scoped>
@-webkit-keyframes spinner-border {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spinner-border {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.spinner-border {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    border: 0.25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: spinner-border .75s linear infinite;
    animation: spinner-border .75s linear infinite;
}

.spinner-border-sm {
    width: 1rem;
    height: 1rem;
    border-width: 0.2em;
}

@-webkit-keyframes spinner-grow {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    50% {
        opacity: 1;
    }
}

@keyframes spinner-grow {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    50% {
        opacity: 1;
    }
}

.spinner-grow {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    background-color: currentColor;
    border-radius: 50%;
    opacity: 0;
    -webkit-animation: spinner-grow .75s linear infinite;
    animation: spinner-grow .75s linear infinite;
}

.spinner-grow-sm {
    width: 1rem;
    height: 1rem;
}

.bg-login {
    background-image: url('../../../public/img/background_login.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
</style>
